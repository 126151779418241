<template>
    <div v-if="this.pageData">
        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" menuparent="get involved"/>
        <main>
            <section class="green-banner" v-if="this.pageData.attributes.field_description_w_summary.summary">
                <div class="container"> <p> {{ this.pageData.attributes.field_description_w_summary.summary }} </p> </div>
            </section>
            <div class="publications">
                <div class="container">
                    <section class="items">
                        <div v-for="(p, index) in pubsData" :key="'pub'+index" class="publication">
                            <div class="image">
                                <span class="category">{{ p.attributes.field_publication_type }}</span>
                                <picture>
                                    <img class="cover" :src="coverimage(p)" :alt="p.relationships.field_image.data.meta.alt">
                                </picture>
                                <!--<div class="download" v-if="p.field_attachment.length">
                                    <img src="../assets/icon-download.svg" alt="download logo">
                                </div>-->
                            </div>
                            <div class="text">
                                <time>{{ formatdate(p) }}</time>
                                <h2> {{ p.attributes.title }} </h2>
                                <p class="author" v-for="(a, i) in p.attributes.field_publication_author" :key="'author'+i+index">
                                    {{ a  + (i==p.attributes.field_publication_author.length-1 ? '' : ', ') }}
                                </p>
                                <div class="description" v-html="p.attributes.field_description.value">
                                </div>

                                <div class="attachments-container">
                                    <a v-for="(att, ind) in p.field_attachment" :key="'attach'+ind"
                                        class="attachments" :href="endpoint + att.attributes.uri.url" download target="_blank"
                                    > {{ getAttDescription(index, ind) }} </a>
                                </div>
                                
                                <div class="logo">
                                    <p>Publication of {{p.relationships.field_publisher.data.meta.alt}}</p>
                                    <picture>
                                        <img :src="pubimage(p)" :alt="p.relationships.field_publisher.data.meta.alt">
                                    </picture>
                                    
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>

    </div>
</template>

<script>
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import { fetchNodes, fetchSinglePage } from '../libs/drupalClient'
export default {
    name: 'read-our-publications',
    components: {
        HeaderIntroImage
    },
    data: () => {
        return {
            pageData: null,
            currentPageID: "bd9e58cb-6e85-4358-972e-fc10be8cb503",
            pubsData: null
        }
    },
    computed: {
        endpoint (){
            return process.env.VUE_APP_ENDPOINT
        },
    },
    methods: {
        /* filetodownload(p) {
            return process.env.VUE_APP_ENDPOINT + p.field_attachment.attributes.uri.url
        }, */
        formatdate(p) {
            return new Date(p.attributes.field_date).toLocaleString('en', {
                year: 'numeric',
                month: 'long',
                //day: 'numeric'
            })
        },
        coverimage(p) {
            if(p.field_image)
                return process.env.VUE_APP_ENDPOINT + p.field_image.attributes.uri.url
            else
                return require('../assets/publication-placeholder.jpg')
        },
        pubimage(p) {
            if(p.field_publisher)
                return process.env.VUE_APP_ENDPOINT + p.field_publisher.attributes.uri.url
            else
                return ''
        },
        getAttDescription(i, pi) {
            var d = this.pubsData[i].relationships.field_attachment.data[pi].meta.description
            if(d=='')
                d = this.pubsData[i].field_attachment[pi].attributes.filename
            
            return d
        }
    },
    mounted() {
        fetchSinglePage(this.currentPageID, {
            include: ['field_page_banner', 'field_page_images', 'field_ref_ui_text']
        })
        .then(res => {
            this.pageData = res
            //console.log('pagedata', res)
        })

        fetchNodes('publication', {
            include: ['field_image', 'field_publisher', 'field_attachment']
        }).then(res => {
            this.pubsData = res
            //console.log('all publications', this.pubsData)
        })
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

.logo img {
    max-height: 100px;
    max-width: 100px;
}
    
.publications {
    background-color: #EBF0F5;
    padding-top: 3rem;
    padding-bottom: 3rem;

    h3 {
        color: #333333;
        font-size: 1.375rem;
    }

    .filters {
        button {
            color: #fff;
            background-color: $darkblue;
            padding: 10px;
            font-weight: 400;
            margin: 5px 0px;
            font-size: 0.875rem;
            border-radius: 5px;
        }

        .checkboxes { margin-top: 1rem; }
        .radio-buttons { margin: 2rem 0rem; }
    }

    .items {
        .publication { margin-bottom: 6rem; }

        .image {
            position: relative;
            background-color: #fff;
            width: calc(100vw - 30px);
            height: calc(100vw - 30px);
            display: flex;
            justify-self: center;
            align-items: center;
            margin-bottom: 2rem;
            max-width: 400px;
            max-height: 400px;

            .cover {
                position: absolute;
                top: 50%;
                left: 50%;
                box-shadow: 0px 10px 28px 0px #00000045;
                transform: translate3d(-50%, -50%, 0);
                max-width: 130px;
            }

            .category {
                background-color: $orange;
                color: #fff;
                position: absolute;
                padding: 10px 25px;
                font-weight: 600;
                font-size: 0.875rem;
                top: -10px;
                left: 50%;
                transform: translateX(-50%);
            }

            .download {
                position: absolute;
                margin: 15px;
                width: 45px;
                height: 45px;
                bottom: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 60px;
                border: 1px solid rgb(230, 230, 230);
            }
        }
        time {
            font-size: 0.875rem;
            color: #666666;
        }
        h2 { font-size: 1.5rem; }
        .author {
            color: $orange;
            font-size: 1rem;
            font-weight: 500;
            margin-top: 0rem;
        }
        .attachments-container {
            font-size: 1rem;
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid rgb(216, 216, 216);
        }
        .logo {
            margin-top: 2rem;
            p { font-size: 0.875rem; }
        }
    }
}

@media (min-width:$container-breakpoints-sm) {
    .publications {
        .items {
            .image {
                width: 400px;
                height: 400px;
                .cover { max-width: 190px; }
            }
        }
    }
}

@media (min-width:$container-breakpoints-md) {
    .publications {
        .filters button {
            display: inline-block;
            width: auto;
            padding: 10px 30px;
            margin-right: 10px;
        }

        .publication {
            display: flex;
            .image { min-width: 400px; }
            .text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 4rem;
            }
        }
        .items .logo { margin-top: 0rem; }
    }
}

@media (min-width:$container-breakpoints-xxl) {
    .publications {
        .items {
            time { font-size: 1.25rem; }
            h2 { font-size: 2rem; }
            .author { font-size: 1.125rem; }
        }
    }
}

</style>