import { render, staticRenderFns } from "./ReadOurPublications.vue?vue&type=template&id=5ddf08aa&scoped=true&"
import script from "./ReadOurPublications.vue?vue&type=script&lang=js&"
export * from "./ReadOurPublications.vue?vue&type=script&lang=js&"
import style0 from "./ReadOurPublications.vue?vue&type=style&index=0&id=5ddf08aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ddf08aa",
  null
  
)

export default component.exports